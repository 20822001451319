.tooltip-container {
    max-width: 400px !important;
    min-width: 350px !important;
    border-radius: 15px !important;
}
.tooltip-container .introjs-disabled {
    display: none;
}

.tooltip-container .introjs-nextbutton,
.tooltip-container .introjs-prevbutton {
    padding: 6px 8px 4px 8px;
}

.tooltip-container .introjs-nextbutton,
.introjs-nextbutton:hover {
    background-color: #f58732 !important;
    border-radius: 22px;
    color: #ffffff !important;
    font-size: 14px;
    text-shadow: none !important;
}

.tooltip-container .introjs-nextbutton:focus {
    border: none;
    box-shadow: none;
}

.tooltip-container .introjs-prevbutton,
.introjs-prevbutton:hover,
.introjs-prevbutton:focus {
    border: 1px solid #f58732 !important;
    border-radius: 22px;
    background-color: #ffffff !important;
    color: #f58732 !important;
    font-size: 14px;
    text-shadow: none !important;
    box-shadow: none !important;
}

.tooltip-container .introjs-tooltiptext {
    text-align: center;
}

.tooltip-container .introjs-tooltiptext h2 {
    color: #42929d;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 1;
    margin-bottom: 15px;
    width: 100%;
}
.tooltip-container .introjs-tooltiptext p {
    color: #414048;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 1.2;
}

.tooltip-container .introjs-tooltiptext ol,
.tooltip-container .introjs-tooltiptext ul {
    text-align: left;
}

.tooltip-container .introjs-bullets ul li a.active {
    background-color: #7b9aaf;
}
